<template>
  <svg
    id="info"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    aria-hidden="true"
  >
    <path
      id="Path_482"
      data-name="Path 482"
      d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.4,6.4,0,0,1,10,16.4Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    />
    <circle
      id="Ellipse_34"
      data-name="Ellipse 34"
      cx="1"
      cy="1"
      r="1"
      transform="translate(7 3.889)"
      fill="currentColor"
    />
    <path
      id="Path_483"
      data-name="Path 483"
      d="M12,10a.917.917,0,0,0-1,.8v3.994a.917.917,0,0,0,1,.8.917.917,0,0,0,1-.8V10.8A.917.917,0,0,0,12,10Z"
      transform="translate(-4 -3.054)"
      fill="currentColor"
    />
  </svg>
</template>
